const VALID_CONTENT_PAGE_PARAMS = ['page', 'category', 'tag']

export const getCleanContentPageUrl = (url: string) => {
  const split = url.split('?')
  const urlWithoutQuery = split[0]
  const params = split[1]

  const searchParams = new URLSearchParams(params)

  var paramsToDelete = Array.from(searchParams.keys()).filter(
    p => !VALID_CONTENT_PAGE_PARAMS.includes(p)
  )

  paramsToDelete.forEach(p => searchParams.delete(p))

  const contentPageUrl =
    searchParams.toString() != '' ? `${urlWithoutQuery}?${searchParams}` : urlWithoutQuery

  return contentPageUrl
}
