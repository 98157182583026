import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useCookieState } from '@dustin-web/microsite-components/src/hooks/use-cookie-state'
import { useQueryState } from '@dustin-web/microsite-components/src/hooks/use-query-state'
import { IncomingMessage } from 'node:http'

const cookies = new Cookies()
const PREVIEW_QUERY_PARAM = 'preview'
const PREVIEW_COOKIE_NAME = 'dustin.content.preview'

export const PreviewInfoBar = () => {
  return (
    <div className="u-bg-light-blue t-light-blue u-text-center">
      PREVIEW (
      <a
        href=""
        onClick={e => {
          e.preventDefault()
          cookies.set(PREVIEW_COOKIE_NAME, 'false', {
            path: '/',
          })
          window.location.reload()
        }}>
        exit preview mode
      </a>
      )
    </div>
  )
}

export const usePreviewMode = () => {
  const { getRawCookie } = useCookieState()
  const { setQueryState } = useQueryState()
  const preview = getRawCookie(PREVIEW_COOKIE_NAME) === 'true'

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has(PREVIEW_QUERY_PARAM)) {
      cookies.set(PREVIEW_COOKIE_NAME, params.get(PREVIEW_QUERY_PARAM), {
        path: '/',
      })

      setQueryState({
        [PREVIEW_QUERY_PARAM]: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { preview }
}

export const isPreviewRequest = (
  req: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string
    }>
  }
) => {
  const preview = req.cookies[PREVIEW_COOKIE_NAME] === 'true'
  return preview
}
